export default `
  <h1>什么是热量预算？</h1>
  <h5>热量预算=基础代谢+日常活动消耗</h5>
  <p>热量预算是根据个人情况，计算出每日所需食物热量摄入值。它既能保证摄入热量值满足身体所需，又能帮助您达到健康减重的目的。</p>
  <h5>热量减肥法介绍</h5>
  <ul>
      <li>1.根据你的每日热量预算，通过饮食摄入或运动消耗制造10%-20%热量亏空、来获得更快的 减重效果。</li>
      <li>2.主张以建立健康的饮食习惯，来帮助用户实现终极的减肥成功，避免因过度节食带来的暴食与反弹，是目前全球公认的科学减肥方法。</li>
    </ul>
`;
