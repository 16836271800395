/**
 * Created by jimmy on 2019/11/8.
 */
/**
 * Created by jimmy on 2019/8/13.
 */
import 'whatwg-fetch';
import * as Utils from './utils';
import * as CONST from './const';

export const getHeaders = (conf = {}) => {
	const _header = {
		"Content-Type":"application/json; charset=utf-8",
		"Auth-Token": '',
		...conf
	};
	return _header;
};

export const post = async (settings) => {
	let url = CONST.apiPrefix + settings.url;
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'POST',
		headers: getHeaders(settings.headers),
		body: JSON.stringify(settings.data || {})
	});
	Utils.loading.hide();
	// if(response.status && response.status === 401) {
	// 	Utils._alert('登录信息过期，请重新登录');
	// 	setTimeout(() => {
	// 		window.location.href = '/login';
	// 	}, 500);
	// 	return;
	// }
	
	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};

export const get = async (settings = {}) => {
	let url = CONST.apiPrefix + settings.url;
	
	if(settings.data) {
		let queryArr = [];
		for(let i in settings.data) {
			if(i && settings.data[i]){
				queryArr.push(`${i}=${settings.data[i]}`);
			}
		}
		url += '?' + queryArr.join('&');
	}
	
	Utils.loading.show();
	let response = await window.fetch(url, {
		method: 'GET',
		headers: getHeaders(settings.headers)
	});
	Utils.loading.hide();
	// if(response.status && response.status === 401) {
	// 	Utils._alert('登录信息过期，请重新登录');
	// 	setTimeout(() => {
	// 		window.location.href = '/login';
	// 	}, 500);
	// 	return;
	// }
	
	response = await response.json();
	if(!settings.hideError && response.ok == -1) {
		Utils._alert(response.msg);
		return false;
	}
	return response;
};