/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import './index.less';

import IMG_BLOCK_1 from './images/1-体态管理.png';
import IMG_BLOCK_2 from './images/2-燃脂塑形.png';
import IMG_BLOCK_3 from './images/3-食物查询.png';
import IMG_BLOCK_4 from './images/4-日常记录.png';

import IMG_QRCODE_SERVICE from './images/service.png';
import IMG_QRCODE_IOS from './images/ios.png';
import IMG_QRCODE_ANDROID from './images/android.png';
// import IMG_BLOCK_1_2 from './images/block-1-2.png';
// import IMG_BLOCK_2_1 from './images/block-2-1.png';
// import IMG_BLOCK_3_1 from './images/block-3-1.png';

import IMG_GABA from './images/gaba.png';
import IMG_LOGO from './images/logo.png';

const TABS = [
	{ label: '体态管理', id: 'ttgl' },
	{ label: '燃脂塑形', id: 'rzsx' },
	{ label: '食物热量', id: 'swrl' },
	{ label: '记录打卡', id: 'jldk' },
	// { label: '加入我们', id: 'jrwm' },
];
const getById = id => {
	const result = TABS.filter(t => t.id == id);
	return result[0];
}

const PageAbout = props => {
	const [ tab, setTab ] = useState(TABS[0]);

	const scrollToAnchor = (anchorName) => {
		if (anchorName) {
			let anchorElement = document.getElementById(anchorName);
			if(anchorElement) { anchorElement.scrollIntoView(); }
		}
	};

	document.addEventListener('scroll', function(e) {
		const lastKnownScrollPosition = window.scrollY + 62;
		const ttglotop = document.querySelector('#ttgl').offsetTop;
		const rzsxotop = document.querySelector('#rzsx').offsetTop;
		const swrlotop = document.querySelector('#swrl').offsetTop;
		const jldklotop = document.querySelector('#jldk').offsetTop;
		// const jrwmotop = document.querySelector('#jrwm').offsetTop;

		// if(lastKnownScrollPosition + 63 >= jrwmotop){
		// 	setTab(getById('jrwm'));
		// }else
		if(lastKnownScrollPosition >= jldklotop){
			setTab(getById('jldk'));
		}else if(lastKnownScrollPosition >= swrlotop){
			setTab(getById('swrl'));
		}else if(lastKnownScrollPosition >= rzsxotop){
			setTab(getById('rzsx'));
		}else if(lastKnownScrollPosition >= ttglotop) {
			setTab(getById('ttgl'));
		}
	});

	return (
		<section className="home-container" style={{ minHeight: window.innerHeight }}>
			<div className="navs" style={{ width: window.innerWidth }}>
				<img src={IMG_LOGO} style={{ width: 150, height: 60 }} />
				<div className="nav-wrap">
					{
						TABS.map((item, idx) => {
							return (
								<div className={`nav-item${ item.id == tab.id ? ' selected' : ''}`} key={idx} onClick={() => {
									setTab(item);
									scrollToAnchor(item.id);
								}}>{item.label}</div>
							)
						})
					}
				</div>
			</div>

			<div className="content-block-wrap" style={{ backgroundColor: '#DCEBEE' }} id="ttgl">
				<div className="content-block">
					<div className="text-content">
						<div className="content-title">
							<span>超燃塑形</span>
							<span>体态管理中心</span>
						</div>
						<div className="content-detail">
							<span>为用户提供全面的体态评估，针对具体的体态问题</span>
							<span>提供专业的减脂塑形方案。</span>
							<span>让你在短短几周内足不出户家完成塑形目标</span>
						</div>
						<div className="extra-content">
							<div className="qrcode">
								<img src={IMG_QRCODE_IOS} />
								<img src={IMG_QRCODE_ANDROID} />
							</div>
						</div>
					</div>
					<div className="image-content"><img src={IMG_BLOCK_1} /></div>
				</div>
			</div>

			<div className="content-block-wrap" style={{ backgroundColor: '#F3E8DC' }} id="rzsx">
				<div className="content-block">
					<div className="image-content"><img src={IMG_BLOCK_2} /></div>
					<div className="text-content">
						<div className="content-title">
							<span>全身8大部位</span>
							<span>燃脂塑形</span>
						</div>
						<div className="content-detail">
							<span>汇聚全网各大运动博主的热门跟练课程</span>
						</div>
						<div className="extra-content">
							{
								[
									[ '全身燃脂', '肩颈舒展' ],
									[ '纤细手臂', '胸背拉伸' ],
									[ '腰腹减脂', '臀胯改善' ],
									[ '腿型矫正', '面部护理' ],
								].map((item, idx) => {
									return <div className="bodypart-items" key={idx}><span>{item[0]}</span><span>{item[1]}</span></div>
								})
							}
						</div>
					</div>
				</div>
			</div>

			<div className="content-block-wrap" style={{ backgroundColor: '#E4E2F4' }} id="swrl">
				<div className="content-block">
					<div className="text-content">
						<div className="content-title">
							<span>一键查询</span>
							<span>每餐食物热量</span>
						</div>
						<div className="content-detail">
							<span>超过10w条的食物库信息，随时查看食物的</span>
							<span>热量、蛋白质、碳水化物、脂肪</span>
							<span>根据您的身体指标，定义每日食物摄入热量</span>
						</div>
					</div>
					<div className="image-content"><img src={IMG_BLOCK_3} /></div>
				</div>
			</div>

			<div className="content-block-wrap" style={{ backgroundColor: '#F2E0EC' }} id="jldk">
				<div className="content-block">
					<div className="image-content"><img src={IMG_BLOCK_4} /></div>
					<div className="text-content">
						<div className="content-title">
							<span>日常记录</span>
							<span>卡路里消耗</span>
						</div>
						<div className="content-detail">
							<span>全球公认的热量减肥法</span>
							<span>记录每餐饮食营养三要素，吃的放心</span>
							<span>记录您每日训练燃烧的卡路里</span>
						</div>
					</div>
				</div>
			</div>

			{/*<div className="content-block-wrap" id="jrwm" style={{ alignItems: 'center' }}>
				<div className="content-block2">
					<div className="content-title"><span>欢迎</span><span>加入我们！</span></div>
					<div className="content-desc">LEMON FACE YOGA</div>
					<div className="content-intro2">
						<div className="left-content">
							<span className="bottom-space poi-title">UI设计师</span>
							<span>岗位职责</span>
							<span>1. 参与或负责线上产品客户端日常迭待的UI、UX需求；</span>
							<span>2. 参与或负责移动端banner、专题活动H5、开屏等设计；</span>
							<span className="bottom-space">3. 参与或负责公司新产品的前期构思、调研、团队讨论。</span>

							<span>职位要求</span>
							<span>1. 熟练使用Sketch、Photoshop等设计相关软件，并有较强的设计工具学习能力；</span>
							<span>2. 有基础的交互设计能力；</span>
							<span>3. 有基础的动效设计能力；</span>
							<span>4. 良好的自驱力，能够主动寻找问题，改进工作；</span>
							<span>5. 视野开阔，对设计风格保持开放和学习的心态；</span>
							<span>6. 良好的协作能力，乐于沟通与分享所见所闻所感。</span>
						</div>
						<div className="left-content">
							<span className="bottom-space poi-title">推广主管</span>

							<span>岗位职责</span>
							<span>1. 核心用户的挖掘和互动， 维系社区活跃度；</span>
							<span>2. 对内容进行筛选分层，使优质内容得到充分曝光，把合适的内容推荐感兴趣的用户；</span>
							<span>3. 能够完成用户群0-1的建立和管理；</span>
							<span className="bottom-space">4. 策划系列的线上、线下活动，促进优质内容的分享和沉淀。</span>

							<span>职位要求</span>
							<span>1. 本科以上学历；</span>
							<span>2. 一年以上用户运营经验；</span>
							<span>3. 擅于挖掘、分析用户习惯，善于从用户角度思考和体验产品，懂得抓取用户心理；</span>
							<span>4. 乐于与他人沟通，有网感。</span>
						</div>
					</div>
				</div>
			</div>*/}

			<div className="home-footer">
				<img src={IMG_QRCODE_SERVICE} />
				<span className="clickable" onClick={() => {
					props.history.push('/text/private');
				}}>用户协议&隐私协议</span>
				<span>Copyright ©2022 超燃塑形｜邮箱: 1309313599@qq.com</span>
				<span className="clickable" onClick={() => {
					window.open('https://beian.miit.gov.cn/');
				}}>{ window.location.host == 'www.lemonup.cn' ? '沪ICP备2021009404号-1' : '沪ICP备2022007504号-1' }</span>
				{
					window.location.host != 'www.lemonup.cn' && <span className="clickable"><img src={IMG_GABA} style={{ height: 20 }} />沪公网安备 31011702008566号</span>
				}
			</div>
		</section>
	);
};

export default PageAbout;

