/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';
import * as Utils from '../../common/utils';

import './index.less';

import IMG_BLOCK_1 from './images/h5备份 1.jpg';
import IMG_BLOCK_2 from './images/h5备份 2.jpg';
import IMG_BLOCK_3 from './images/h5备份 3.jpg';
import IMG_BLOCK_4 from './images/h5备份 4.jpg';
import IMG_BLOCK_5 from './images/h5备份 5.jpg';
import IMG_BLOCK_6 from './images/h5备份 6.jpg';
import IMG_BLOCK_7 from './images/h5备份 7.jpg';
import IMG_BLOCK_8 from './images/h5备份 8.jpg';
import IMG_BLOCK_9 from './images/h5备份 9.jpg';
import IMG_BLOCK_10 from './images/h5备份 10.jpg';
import IMG_POP_TIP from './images/弹窗.png';

const PageAbout = props => {
	const androidLink = 'https://guoran.superfat.cn/assets/download/app-release.apk';
	const iosLink = 'https://apps.apple.com/cn/app/%E8%B6%85%E7%87%83%E5%A1%91%E5%BD%A2-%E4%BD%93%E6%80%81%E6%94%B9%E5%96%84%20%E5%BD%A2%E4%BD%93%E5%A1%91%E9%80%A0%20%E9%A5%AE%E9%A3%9F%E5%87%8F%E8%82%A5/id1603940949';
	const [ showTip, setShowTip ] = useState(false);

	Utils.setTitle('超燃塑形·体态管理中心');

	const list = [ IMG_BLOCK_1, IMG_BLOCK_2, IMG_BLOCK_3, IMG_BLOCK_4, IMG_BLOCK_5, IMG_BLOCK_6, IMG_BLOCK_7, IMG_BLOCK_8, IMG_BLOCK_9, IMG_BLOCK_10 ];

	return (
		<section className="home-mobile-container" style={{ minHeight: window.innerHeight }}>
			{
				list.map((item, idx) => {
					return (
						<div key={idx} className="img-wrap">
							{
								idx == 0 && <div className="btn-wrap">
									<div className="btn-item" onClick={() => {
										if(Utils.getUA().isAndroid) return;
										if(Utils.getUA().isInWechat && Utils.getUA().isIOS) {
											setShowTip(true);
										}else{
											window.location.href = iosLink;
										}
									}} />
									<div className="btn-item" onClick={() => {
										if(Utils.getUA().isIOS) return;
										window.location.href = androidLink;
									}} />
								</div>
							}
							{
								idx == list.length - 1 && <div className="beian" onClick={() => {
									window.open('https://beian.miit.gov.cn/');
								}} />
							}
							<img src={item} />
						</div>
					)
				})
			}

			{
				showTip && <div className="wxpoptip" onClick={() => {
					setShowTip(false);
				}}>
					<img src={IMG_POP_TIP} />
				</div>
			}
		</section>
	);
};

export default PageAbout;

