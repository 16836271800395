/**
 * Created by jimmy on 2022/6/1.
 */

import React, {useState, useEffect, useRef} from 'react';

import * as REQUEST from "../../common/request";
import * as Utils from "../../common/utils";

import "./index.less";

const PageH5RegisterModel = props => {
  const rc = Utils.getSearchByKey('rc')

  const [ appConfig, setAppConfig ] = useState({});
  const getAppConfig = async () => {
    const result = await REQUEST.get({
      url: '/config/index'
    });
    if(!result) return;

    setAppConfig(result.data);
  };
  useEffect(() => {
    getAppConfig();
  }, [ 123 ]);

  const [ protocolConfig, setProtocolConfig ] = useState({ flag: false, title: null, content: null })
  const openProtocol = (type, title) => {
    let content = appConfig[type] || null
    if(content) {
      content = content.replace(/\n/ig, '<br />')
    }
    setProtocolConfig({
      flag: true,
      title,
      content
    })
  };

  const [ androidTip, setAndroidTip ] = useState(false);
  const codeRef = useRef(null);
  const [ recommendCode, setRecommendCode ] = useState(rc);
  const [ mobile, setMobile ] = useState('');
  const [ code, setCode ] = useState('');
  const getCode = async () => {
    if(!mobile) {
      Utils._alert('手机号不能为空');
      return;
    }
    const result = await REQUEST.get({
      url: '/user/code/get',
      data: { mobile }
    });
    if(!result) return;

    Utils._alert(result.data.codeMsg);

    codeRef.current.focus();
  };
  const doLogin = async () => {
    if(!mobile) {
      return Utils._alert('请输入有效手机号');
    }
    if(!code) {
      return Utils._alert('请输入验证码');
    }

    const result = await REQUEST.post({
      url: '/user/login',
      data: { code, mobile, recommendCode }
    });
    if(!result) return;

    if(Utils.getUA().isIOS) {
      Utils._alert(`提交成功，快去下载「超燃塑形」，开启塑形人生<br />系统3秒后会自动跳转app store`, 3000);
      setTimeout(() => {
        Utils.appDownloadAction()
      }, 3000)
    }else{
      setAndroidTip(true)
    }
  };

  useEffect(() => {
    Utils.setTitle('欢迎开启超燃塑形人生')
  }, [])

  return (
    <section className="h5-register-container">
      <div className="info">
        <div className="content">
          <span>超燃塑形APP</span>
          <div className="slogan">
            <span>体态评估</span>
            <span>跟练打卡</span>
            <span>饮食记录</span>
          </div>
        </div>
        <div className="cover" />
      </div>
      <div className="register-wrapper">
        <span className="title">欢迎来到超燃塑形APP！</span>
        <div className="former-wrap"><input maxLength={11} placeholder="手机号" onChange={e => setMobile(e.currentTarget.value)} /></div>
        <div className="former-wrap"><input maxLength={6} ref={codeRef} placeholder="验证码" onChange={e => setCode(e.currentTarget.value)} /><div className="vcode" onClick={getCode}>获取验证码</div></div>
        <div className="former-wrap"><input placeholder="推荐码（选填）" disabled={rc} defaultValue={rc} onChange={e => setRecommendCode(e.currentTarget.value)} /></div>
        <div className="submit-btn" onClick={doLogin}>注册</div>
        <div className="protocol">注册即同意超燃塑形<span className="title" onClick={() => openProtocol('userProtocol', '用户协议')}>《用户协议》</span>与<span className="title" onClick={() => openProtocol('privateProtocol', '隐私政策')}>《隐私政策》</span></div></div>
      <div className="bottom-logo" />

      {
        protocolConfig?.flag && <div className="popup-wrap">
          <div className="bg" />
          <div className="content-wrap">
            <div className="title">{protocolConfig?.title}</div>
            <div className="content" dangerouslySetInnerHTML={{ __html: protocolConfig?.content }} />
            <div className="close" onClick={() => {
              setProtocolConfig({ flag: false, content: null, title: null });
            }} />
          </div>
        </div>
      }

      {
        androidTip && <div className="popup-wrap">
          <div className="bg" />
          <div className="content-wrap">
            <div className="title">欢迎开启塑形人生</div>
            <div className="content download-content">
              <span className="a" onClick={Utils.appDownloadAction}>直接下载应用</span>
              <span onClick={() => setAndroidTip(false)}>去应用市场搜索「超燃塑形」下载</span>
            </div>
            <div className="close" onClick={() => setAndroidTip(false)} />
          </div>
        </div>
      }
    </section>
  );
};

export default PageH5RegisterModel;
