/**
 * Created by jimmy on 2019/9/29.
 */

import React, { useState, useEffect } from 'react';

import './index.less';

import * as Request from '../../common/request';

import calorieContent from './content/calorie';
import bmrContent from './content/bmr';
import consumeContent from './content/consume';

const PageModel = props => {
	const [ textContent, setTextContent ] = useState('');
	const getContent = async () => {
		const { target } = props.match.params;
		let textContent = ({
			calorie: calorieContent,
			bmr: bmrContent,
			consume: consumeContent
		})[target];

		// for raw text
		if([ 'user', 'private' ].includes(target)) {
			const result = await Request.get({
				url: `/config/protocol/${target}`
			});
			if(!result) return;

			textContent = result.data.text;
			textContent = textContent.replace(/\n/ig, '<br /><br />');
		}

		setTextContent(textContent);
	};
	useEffect(() =>{
		getContent();
	}, []);

	return (
		<section className="text-container" style={{ minHeight: window.innerHeight }}>
			<div style={{ display: 'flex', flexDirection: 'column' }} dangerouslySetInnerHTML={{ __html: textContent }} />
		</section>
	);
};

export default PageModel;
