/**
 * Created by jimmy on 2019/11/8.
 */

const host = window.location.host;
export const env = (host.indexOf('127.0.0.1') > -1 || host.indexOf('localhost') > -1) ? 'env' : 'product';

// export const apiPrefix = 'http://api.yeekin.cn/v1';
export const apiPrefix = '/api/v1';
export const uploadPrefix = '/api/v1';
export const imgPrefix = env == 'product' ? '/' : 'http://127.0.0.1:12987/';

export const adminTypes = { 1: '超级管理员', 2: '普通管理员', 3: '游客'/* 游客只能浏览大盘数据 */ };
